var AppCalendar = function() {

    return {
        //main function to initiate the module
        init: function() {

            this.initCalendar();

        },

        initCalendar: function() {

            if (!jQuery().fullCalendar) {
                return;
            }

            var calendarSelector = $('.calendar');

            var url = calendarSelector.data('url');

            calendarSelector.removeClass("mobile");

            var  loadScheduledAssignations = function(start,end,callback) {

                $.ajax({
                    type: 'GET',
                    url: url,
                    data: {
                        start: start.format(),
                        end: end.format()
                    },
                    success: function (data) {
                        var events = data.data;
                        callback(events);
                    }
                }).done(function () {

                }).fail(function (data) { //any errors?

                    alert(data);
                });
            };


                calendarSelector.fullCalendar({ //re-initialize the calendar
                    header: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'month,agendaWeek,agendaDay'
                    },
                    nextDayThreshold:'05:00:00',
                    firstDay:1,
                    lang:'es',
                    monthNames: ['Urtarrila','Otsaila','Martxoa','Apirila','Maiatza',
                        'Ekaina','Uztaila','Abuztua','Iraila','Urria','Azaroa','Abendua'],
                    dayNames:['Astelehena','Asteartea','Asteazkena','Osteguna','Ostirala','Larunbata','Igandea'],
                    dayNamesShort:['Ig.','Al.','Ar.','Az.','Og.','Os.','La.'],
                    buttonText: {
                        today:    'gaur',
                        month:    'hilabetea',
                        week:     'astea',
                        day:      'eguna'
                    },
                    eventLimitText:'gehio',
                    eventLimit: 8, // for all non-agenda views
                    views: {
                        agenda: {
                            eventLimit: 10 // adjust to 6 only for agendaWeek/agendaDay
                        }
                    },
                    defaultView: 'month', // change default view with available options from http://arshaw.com/fullcalendar/docs/views/Available_Views/
                    slotMinutes: 15,
                    editable: false,
                    eventDurationEditable:false,
                    droppable: false, // this allows things to be dropped onto the calendar !!!
                    events:  function(start,end,timezone,callback)
                    {
                        loadScheduledAssignations(start,end,callback);
                    },
                    eventMouseover: function (event, jsEvent, view) {
                        if (view.name !== 'agendaDay') {
                            $(jsEvent.target).attr('title', event.title);
                        }
                    },
                    eventClick: function(event) {
                        if (event.url) {
                            window.open(event.url);
                            return false;
                        }
                    }

                });


         }

    };

}();

$(document).ready(function() {
    AppCalendar.init();
});