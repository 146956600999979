function order(start_pos,end_pos,uri){
    var relativeGroup = $('#relative_group').val();
    var relativeGroupParent = $('#relative_group_parent').val();
    $.get(BASE+'/'+uri+'/order/'+start_pos+'/'+end_pos,{relative_group:relativeGroup,lang:relativeGroupLang,parent:relativeGroupParent}, function(data) {

    });
}

function orderInit(uri)
{
    $( "#sortable" ).sortable({
        revert: true,
        start: function(event, ui) {
            var start_pos = ui.item.index();
            ui.item.data('start_pos', start_pos);

        },
        update: function(event, ui) {
            var start_pos = ui.item.data('start_pos');
            var end_pos = $(ui.item).index();
            order(start_pos,end_pos,uri);
        }
    });

    $( "tbody, tr" ).disableSelection();
}
