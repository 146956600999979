function attachOffice() {

    $('#add-fields-row').on('click',function (e) {

        e.preventDefault();

        addOfficeFields();

    });

    $('#main-form').on('click','.remove_office_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });
}

function addOfficeFields(){
    var wrapper = $("#office-fields-wrapper");
    var fieldsCounter = $('.office-row').length;
    var x = fieldsCounter +  1;

    var fieldsHtml = '<div class="col-md-12 mt10 office-row">'+
        '<label class="col-md-5"><select class="form-control" id="offices_list_'+x+'" name="offices['+x+'][office_id]"></select></label>'+
        '<label class="col-md-5"><select class="form-control" id="roles_list_'+x+'" name="offices['+x+'][role]"></select></label>'+
        '<label class="col-md-2"><span class="red-button btn remove_office_row">X</span></div>';

    $(wrapper).prepend(fieldsHtml);

    var options = $('#offices_list_source').find('option').clone();
    var role_options = $('#roles_list_source').find('option').clone();

    console.log(role_options);

    $('#offices_list_'+x).html(options);
    $('#roles_list_'+x).html(role_options);


}