function manageLicenses() {

    $('#add-licenses-row').on('click',function (e) {

        var wrapper = $("#licenses-fields-wrapper");
        var fieldsCounter = $('.licenses-row').length;
        var x = fieldsCounter;

        var fieldsHtml = '<div class="col-md-12 mt10 licenses-row">'+
            '<label class="col-md-2">Data'+
            '<div class="input-group date" id="exit_data['+ x +']">'+
            '<input type="text" class="form-control" name="licenses['+ x +'][exit_date]">'+
            '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
            '</div>'+
            '</label>'+


            '<label class="col-md-2">Egun Kopurua<input type="number" class="form-control" name="licenses['+ x +'][exit_days]"></label>'+
            '<div class="col-lg-4"><input class="form-control" name="licenses['+x+'][file]" type="file"></div>'+

            '<input type="hidden" name="licenses['+ x +'][license_type]" value="normal">'+

            '<label class="col-md-2"><span class="red-button btn remove_license_row">X</span></div>';

        $(wrapper).prepend(fieldsHtml);

        e.preventDefault();

    });

    $('#main-form').on('click','.remove_license_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });
}


function manageSpecialLicenses() {

    $('#add-special-licenses-row').on('click',function (e) {

        var wrapper = $("#special-licenses-fields-wrapper");
        var fieldsCounter = $('.special-licenses-row').length;
        var x = fieldsCounter;

        var fieldsHtml = '<div class="col-md-12 mt10 special-licenses-row">'+
            '<label class="col-md-2">Data'+
            '<div class="input-group date" id="exit_data['+ x +']">'+
            '<input type="text" class="form-control" name="special_licenses['+ x +'][exit_date]">'+
            '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
            '</div>'+
            '</label>'+


            '<label class="col-md-2">Ordu Kopurua<input type="number" class="form-control" name="special_licenses['+ x +'][exit_days]"></label>'+
            '<label class="col-md-2">Arrazoia<select class="form-control" id="special_license_text_'+x+'" name="special_licenses['+x+'][license_text]"></select></label>'+
            '<label class="col-lg-4">Fitxategia<input class="form-control" name="special_license['+x+'][file]" type="file"></label>'+
            '<input type="hidden" name="special_licenses['+ x +'][license_type]" value="special">'+

            '<label class="col-md-2"><span class="red-button btn remove_special_license_row">X</span></div>';

        $(wrapper).prepend(fieldsHtml);

        var text_options = $('#license_text_source').find('option').clone();

        console.log(text_options);

        $('#special_license_text_'+x).html(text_options);

        e.preventDefault();

    });

    $('#main-form').on('click','.remove_special_license_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });
}