function manageComplianceLevels(arrest_date, liberation_date) {

    $('#add-compliance-level-row').on('click',function (e) {
        var wrapper = $("#compliance-level-fields-wrapper");
        var fieldsCounter = $('.compliance-level-row').length;
        var x = fieldsCounter;

        var fieldsHtml = '<div class="compliance-level-row mt20 col-md-12">'+
                '<label class="col-md-2">Maila'+
                    '<div class="input-group">'+
                        '<select class="form-control compliance-level-select" id="compliance_level_text" name="compliance_levels['+x+'][compliance_level]"></select>'+
                    '</div>'+
                '</label>'+
                '<label class="col-md-2">Data'+
                    '<div class="input-group date">'+
                        '<input type="text" class="form-control input-date" name="compliance_levels['+x+'][date]" value="">'+
                        '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
                    '</div>'+
                '</label>'+
                '<label class="col-md-2">'+
                    '<span class="red-button btn remove_compliance_level_row">X</span>'+
                '</label>'+
            '</div>';



        $(wrapper).prepend(fieldsHtml);

        var text_options = $('#compliance_levels_text_source').find('option').clone();
        $('#compliance_level_text').html(text_options);
        $(".compliance-level-select" ).trigger( "change" );

        e.preventDefault();

    });

    $('#main-form').on('click','.remove_compliance_level_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });

    $('#main-form').on('change','.compliance-level-select',function (e) {
        liberation = new Date(moment(liberation_date, 'YYYY-MM-DD'));
        arrest = new Date(moment(arrest_date, 'YYYY-MM-DD'));
        if(liberation != 'Invalid Date' && arrest != 'Invalid Date'){
            var oneDay = 24*60*60*1000;
            var diffDays = Math.round(Math.abs((liberation.getTime() - arrest.getTime())/(oneDay)));

            var option = $(this).val();

            var part = "1";

            switch(option){
                case 'quarter':
                    part = 0.25;
                    break;
                case 'half':
                    part = 0.5;
                    break;
                case 'two-thirds':
                    part = 0.66;
                    break;
                case 'three-quarters':
                    part = 0.75;
                    break;
                case 'four_fifths':
                    part = 0.8;
                    break;
                case 'seven_eighths':
                    part = 0.875;
                    break;
                case 'four_fourths':
                case 'freedom':
                    part = 1;
                    break;
            }

            purpose_date = new Date(arrest.getTime()+(Math.round(diffDays*part)*oneDay));

            $(this).closest('.compliance-level-row').find('.input-date').val(moment(purpose_date).format('YYYY-MM-DD'));
        }
        e.preventDefault();
    });
}