function manageInternationalSentences() {

    $('#add-international-sentence-row').on('click',function (e) {
        var wrapper = $("#international-sentences-fields-wrapper");
        var fieldsCounter = $('.international-sentence-row').length;
        var x = fieldsCounter;

        var fieldsHtml = '<div class="international-sentence-row mt20 col-md-10">'+
                                '<div class="col-md-11">'+
                                    '<div class="row">'+
                                        '<div class="col-lg-2">'+
                                            '<label>Nazioartean kondenatua</label>'+
                                            '<input class="switch" checked="checked" name="international_sentences['+x+'][international_sentence]" type="checkbox" value="1">'+
                                        '</div>'+
                                        '<label class="col-lg-4" >Estatua<span class="text-red">*</span>'+
                                            '<select class="form-control" name="international_sentences['+x+'][is_state]" id="international_arrest_state_'+x+'"></select>'+
                                        '</label>'+
                                        '<label class="col-lg-2" >Urteak<span class="text-red">*</span>'+
                                            '<input class="form-control" name="international_sentences['+x+'][is_penalty_years]" type="number">'+
                                        '</label>'+
                                        '<label class="col-lg-1" >Hilabeteak<span class="text-red">*</span>'+
                                            '<input class="form-control" name="international_sentences['+x+'][is_penalty_months]" type="number">'+
                                        '</label>'+
                                        '<label class="col-lg-1" >Egunak<span class="text-red">*</span>'+
                                            '<input class="form-control" name="international_sentences['+x+'][is_penalty_days]" type="number">'+
                                        '</label>'+
                                        '<div class="col-lg-2">'+
                                            '<label>Balizko nazioarteko akumulazioa</label>'+
                                            '<input class="switch" name="international_sentences['+x+'][is_possible_international_accumulation]" type="checkbox" value="1">'+
                                        '</div>'+
                                    '</div>'+
                                    '<div class="row">'+
                                        '<div class="col-lg-3" >'+
                                            '<label>Kondena eguna<span class="text-red">*</span></label>'+
                                            '<div class="input-group date">'+
                                                '<input class="form-control" name="international_sentences['+x+'][is_arrest_date]" type="text">'+
                                                '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
                                            '</div>'+
                                        '</div>'+
                                        '<div class="col-lg-3" >'+
                                            '<label>Behin betiko entrega<span class="text-red">*</span></label>'+
                                            '<div class="input-group date">' +
                                                '<input class="form-control" name="international_sentences['+x+'][is_final_delivery]" type="text">'+
                                                '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
                                            '</div>'+
                                        '</div>'+
                                        '<div class="col-lg-4">'+
                                            '<label for="international_sentences">Nazioartean atxilotu eta zigortua, Fitxategia igo:</label>'+
                                            '<input class="form-control" name="international_sentences['+x+'][file]" type="file">'+
                                        '</div>'+
                                        '</div><br/><hr>'+
                                '</div>'+
                                '<label class="col-md-1">'+
                                    '<span class="red-button btn remove_international_sentence_row">X</span>'+
                                '</label>'+
                            '</div>'+
                        '</div>';



        $(wrapper).prepend(fieldsHtml);

        $(".switch").bootstrapSwitch({
            size: 'small',
            onColor: 'success',
            onText: 'bai',
            offText: 'ez'
        });

        var text_options = $('#states_text_source').find('option').clone();
        $('#international_arrest_state_'+x).html(text_options);

        e.preventDefault();

    });

    $('#main-form').on('click','.remove_international_sentence_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });

}