function attachVisit() {
    $('#add-children-visit-row').on('click',function (e) {
        var csrfVar = $('meta[name="csrf-token"]').attr('content');
        var children_id = $('#children_id').val();
        var wrapper = $("#children-visits-fields-wrapper");
        var fieldsCounter = $('.visit-row').length;
        var x = fieldsCounter +  1;

        var fieldsHtml = '<div class="col-lg-12 mt20">'+
            '<form method="post" action="/admin/children-visits">'+
                '<input name="_token" value="'+csrfVar+'" type="hidden">'+
                '<input name="children_id" value="'+children_id+'" type="hidden">'+
                '<div class="row">'+
                    '<label class="col-lg-4">'+
                        'Presoa <span class="text-red">*</span>'+
                        '<select name="prisoner_id" class="form-control" id="prisoners_list"></select>'+
                    '</label>'+

                    '<label class="col-lg-3">'+
                        'Harremana'+
                        '<select name="relationship" class="form-control" id="relationships_list"></select>'+
                    '</label>'+
                '</div>'+



                '<div class="col-lg-12 mt20">'+
                    '<div class="row">'+
                        '<label class="col-lg-4">'+
                        '<button type="submit" class="btn btn-primary">Gorde</button>'+
                        '</label>'+
                '</div>'+
            '</form>';


        $(wrapper).prepend(fieldsHtml);

        var prisoners_options = $('#prisoners_list_source').find('option').clone();
        var relationship_options = $('#relationship_list_source').find('option').clone();

        $('#prisoners_list').html(prisoners_options);
        $('#relationships_list').html(relationship_options);

        e.preventDefault();

    });

    $('#main-form').on('click','.remove_visit_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });

}