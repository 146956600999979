function manageGradeResources() {

    $('#add-grade-resources-row').on('click',function (e) {
        
        var wrapper = $("#grade-resources-fields-wrapper");
        var fieldsCounter = $('.grade-resource-row').length;
        var x = fieldsCounter;

        var fieldsHtml = '<div class="activity-row mt20 col-md-12">'+

            '<div class="col-md-3">'+
            '<label class="col-lg-12" >Tratamentu juntaren ebazpena<br/>'+
        '<input class="switch" id="treatment_joint_resolution"  name="grade_resources['+x+'][treatment_joint_resolution]" type="checkbox" value="1">'+
            '</label>'+

            '<label class="col-md-12">Data'+
            '<div class="input-group date" >'+
            '<input type="text" class="form-control" name="grade_resources['+x+'][tjs_date]" value="">'+
            '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
        '</div>'+
        '</label>'+

        '<label class="col-lg-12" >Errekurritua<br/>'+
            '<input class="switch" id="tjs_appeal"  name="grade_resources['+x+'][tjs_appeal]" type="checkbox" value="1">'+
            '</label>'+
            '<label class="col-lg-12" >SGIP ebazpena<br/>'+
        '<input class="switch" id="tjs_final_resolution"  name="grade_resources['+x+'][tjs_final_resolution]" type="checkbox" value="1">'+
            '</label>'+

            '<label class="col-md-12">Data'+
            '<div class="input-group date">'+
            '<input type="text" class="form-control" name="grade_resources['+x+'][tjs_final_resolution_date]" value="">'+
            '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
        '</div>'+
        '</label>'+
        '<label class="col-lg-12" >Errekurritua<br/>'+
            '<input class="switch" id="tjs_final_resolution_appeal"  name="grade_resources['+x+'][tjs_final_resolution_appeal]" type="checkbox" value="1">'+
            '</label>'+
            '<div class="col-lg-12">'+
            '<label for="files[exit]">Igo fitxategia:</label>'+
        '<input class="form-control" name="grade_resources['+x+'][tjs_file]" type="file">'+
            '</div>'+
            '</div>'+
            '<div class="col-md-3">'+
            '<label class="col-lg-12" >Zaintza epaitegiaren ebazpena<br/>'+
        '<input class="switch" id="custody_court_resolution"  name="grade_resources['+x+'][custody_court_resolution]" type="checkbox" value="1">'+
            '</label>'+
            '<label class="col-md-12">Data'+
            '<div class="input-group date">'+
            '<input type="text" class="form-control" name="grade_resources['+x+'][ccr_date]" value="">'+
            '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
        '</div>'+
        '</label>'+
        '<label class="col-lg-12" >Errekurritua<br/>'+
            '<input class="switch" id="ccr_appeal"  name="grade_resources['+x+'][ccr_appeal]" type="checkbox" value="1">'+
            '</label>'+
            '<div class="col-lg-12">'+
            '<label for="files[exit]">Igo fitxategia:</label>'+
        '<input class="form-control" name="grade_resources['+x+'][ccr_file]" type="file">'+
            '</div>'+
            '</div>'+
            '<div class="col-md-3">'+
            '<label class="col-lg-12" >Zaintza epaitegiaren erreforma helegitearen ebazpena<br/>'+
        '<input class="switch" id="custody_court_ruling_appeals_resolution"  name="grade_resources['+x+'][custody_court_ruling_appeals_resolution]" type="checkbox" value="1">'+
            '</label>'+
            '<label class="col-md-12">Data'+
            '<div class="input-group date" >'+
            '<input type="text" class="form-control" name="grade_resources['+x+'][ccrar_date]" value="">'+
            '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
        '</div>'+
        '</label>'+
        '<label class="col-lg-12" >Errekurritua<br/>'+
            '<input class="switch" id="ccrar_appeal"  name="grade_resources['+x+'][ccrar_appeal]" type="checkbox" value="1">'+
            '</label>'+
            '<div class="col-lg-12">'+
            '<label for="files[exit]">Igo fitxategia:</label>'+
        '<input class="form-control" name="grade_resources['+x+'][ccrar_file]" type="file">'+
            '</div>'+
            '</div>'+
            '<div class="col-md-2">'+
            '<label class="col-lg-12" >'+
            'Audientzia nazionaleko ebazpena<br/>'+
        '<input class="switch" id="national_court_resolution"  name="grade_resources['+x+'][national_court_resolution]" type="checkbox" value="1">'+
            '</label>'+
            '<label class="col-md-12">Data'+
            '<div class="input-group date" >'+
            '<input type="text" class="form-control" name="grade_resources['+x+'][ncr_date]" value="">'+
            '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
        '</div>'+

        '</label>'+
        '<div class="col-lg-12">'+
            '<label for="files[exit]">Igo fitxategia:</label>'+
        '<input class="form-control" name="grade_resources['+x+'][ncr_file]" type="file">'+
            '</div>'+
            '</div>'+
            '<label class="col-md-1">'+
            '<span class="red-button btn remove_activity_row">X</span>'+
            '</label>'+
            '</div>';

        $(wrapper).prepend(fieldsHtml);

        $(".switch").bootstrapSwitch({
            size: 'small',
            onColor: 'success',
            onText: 'bai',
            offText: 'ez'
        });


        e.preventDefault();

    });

    $('#main-form').on('click','.remove_work_and_study_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });
}