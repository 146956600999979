function prisonerSearcher()
{
    $('#prisoner_id').select2({
        placeholder: 'Bilatu presoa',
        minimumInputLength: 2,
        width: '100%',
        allowClear: true,
        multiple: false,
        ajax: {
            url: BASE+'/prisoners/search/',
            dataType: 'json',
            quietMillis: 100,
            data: function (term)
            {
                return {
                    term: term
                };
            },
            results: function (data)
            {
                var myResults = [];

                var alias = '';

                $.each(data.results, function (index, item) {
                    if(item.alias != '')
                    {
                        alias += ' ('+item.alias+')';
                    }
                    myResults.push({
                        id: item.id,
                        text: item.name + ' '+item.last_name + alias
                    });
                });
                return {
                    results: myResults
                };
            }
        }
    });

}


