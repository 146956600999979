$(function()
{
    $(".switch").bootstrapSwitch({
        size: 'small',
        onColor: 'success',
        onText: 'bai',
        offText: 'ez'
    });

    $('#colorpicker-popup').colorpicker();

});

