function regions(domIdent, countyId, selected) {

    if (typeof(selected) === "undefined" || selected === null) selected = 0;

    $.get( '/api/counties/' + countyId+'/regions', function (data) {

        var regions = [];

        var theOption = document.createElement("option");

        var theText = document.createTextNode('--');

        theOption.appendChild(theText);

        theOption.setAttribute("value", 0);

        regions.push(theOption);

        for (var i in  data.data) {

            theOption = document.createElement("option");

            theText = document.createTextNode(data.data[i].name);

            theOption.appendChild(theText);

            theOption.setAttribute("value", data.data[i].id);

            if (selected == data.data[i].id) {
                theOption.setAttribute("selected", true);
            }

            regions.push(theOption);
        }

        $(domIdent).html(regions);
    });
}


function towns(domIdent, regionId, selected) {

    if (typeof(selected) === "undefined" || selected === null) selected = 0;

    $.get('/api/regions/' + regionId+'/towns', function (data) {

        var towns = [];

        var theOption = document.createElement("option");

        var theText = document.createTextNode('--');

        theOption.appendChild(theText);

        theOption.setAttribute("value", 0);

        towns.push(theOption);

        for (var i in  data.data) {

                theOption = document.createElement("option");

                theText = document.createTextNode(data.data[i].name);

                theOption.appendChild(theText);

                theOption.setAttribute("value", data.data[i].id);

                if (selected == data.data[i].id) {

                    theOption.setAttribute("selected", true);
                }

                towns.push(theOption);
        }

        $(domIdent).html(towns);
    });
}
