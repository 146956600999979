$(document).ready(function()
{
    $.fn.editable.defaults.mode = "inline";

    $(".editable-reference-name").editable({
        params: function (params) {  //params already contain `name`, `value` and `pk`
            var data = {};
            data['name'] = params.value;
            return data;
        }
    });


    $("#files-table").on("click",".delete-file",function(e){
        e.preventDefault();

        var r = confirm("Are you sure?");
        if (r == true) {
            $.get(this.href, function(data){

                $('#file-tr-'+data.results).remove();

            }).fail(function(xhr, ajaxOptions, thrownError) { //any errors?

                alert(thrownError); //alert with HTTP error

            });
        }
        return false;
    });

});