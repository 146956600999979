function managePenalties() {

    $('#add-penalties-row').on('click',function (e) {

        var wrapper = $("#penalties-fields-wrapper");
        var fieldsCounter = $('.penalty-row').length;
        var x = fieldsCounter;

        var fieldsHtml = '<div class="penalty-row mt20 col-md-12">'+

            '<label class="col-md-2">Data'+
                '<div class="input-group date">'+
                    '<input type="text" class="form-control" name="penalties['+x+'][penalty_date]" value="">'+
                    '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
                '</div>'+
            '</label>'+
            '<label class="col-lg-4">Zigor maila <span class="text-red">*</span>'+
                '<select class="form-control" id="penalti_level_text_'+x+'" name="penalties['+x+'][penalty_level]"></select>'+
            '</label>'+
            '<label class="col-lg-4">Fitxategia<input class="form-control" name="penalties['+x+'][file]" type="file"></label>'+
            '<label class="col-md-2"><span class="red-button btn remove_penalty_row">X</span></div>';

        $(wrapper).prepend(fieldsHtml);

        var text_options = $('#penalti_level_text_source').find('option').clone();

        $('#penalti_level_text_'+x).html(text_options);

        e.preventDefault();

    });

    $('#main-form').on('click','.remove_penalty_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });
}