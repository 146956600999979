Dropzone.options.myAwesomeDropzone = {
    paramName: "file",
    maxFilesize: 6, // MB
    accept: function(file, done) {
        done();

    },
    success:function(file,response)
    {
        completeFilesTable(response);

    }

};

function completeFilesTable(response)
{
    var filesTable = $("#files-table");
    var tableTo = filesTable.find("tbody tr");

    var imageurl = "/files/"+response.results.path+"/"+response.results.hash;

    var htmlContent = "<tr id='file-tr-"+response.results.id+"'>" +
        "<td><a class='btn btn-link' href='"+imageurl+"'><i class='fa fa-download'></i><small> jaitsi</small></a>" +
        "<small id='editable-ref-i-"+response.results.id+"'  class='editable-click editable-empty editable-reference editable-reference-name' data-type='text'  data-pk='"+response.results.id+"'  data-url='/admin/files/"+response.results.id+"' data-title='change name'>"+response.results.name+"</small></td>"+
        "<td><div class='btn-group'><a href='/admin/files/delete/"+response.results.id+"' class='btn btn-link delete-file'><small>" +
        "<i class='fa fa-trash-o'></i>&nbsp;Ezabatu</small></a></div></td>" +
        "</tr>";

    if(tableTo.length > 0)
    {
        tableTo.first().before(htmlContent);

    } else
    {
        filesTable.find("tbody").append(htmlContent);
    }

}

(function()
{
    $("#files-table").on('click','.editable-reference-name',function(){

        $(".editable-reference-name").editable({
            params: function (params) {  //params already contain `name`, `value` and `pk`
                var data = {};
                data['name'] = params.value;
                return data;
            }
        });

    });

    $('form[data-ajax]').on('submit',function(e)
    {
        var form = $(this);
        var method = form.find('input[name="_method"]').val() || 'POST';
        var url = form.prop('action');
        $.ajax({
            type: method,
            url: url,
            data: form.serialize(),
            success: function(data){
                $('.alert-success').html(data.message).fadeIn(300).delay(2500).fadeOut(300);
                completeFilesTable(data);
            }
        }).done(function() {
            form.trigger('reset');
        }).fail(function(xhr, ajaxOptions, thrownError) {
            $('.alert-danger').html(xhr.responseText).fadeIn(300).delay(2500).fadeOut(300);
        });
        e.preventDefault();
    });

})();