function attachTransfer() {

    $('#add-transfer-fields-row').on('click',function (e) {
        console.log('add transfer');
        e.preventDefault();

        addTransferFields();

    });

    $('#main-form').on('click','.remove_transfer_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });
}

function addTransferFields(){

    var wrapper = $("#transfer-fields-wrapper");
    var fieldsCounter = $('.transfer-row').length;
    var x = fieldsCounter +  1;

    var fieldsHtml = '<div class="col-md-12 mt10 transfer-row">'+
        '<label class="col-md-3"><label>Nora</label><select class="form-control" id="prisons_list_'+x+'" name="transfers['+x+'][destination_prison_id]"></select></label>'+
        '<label class="col-md-3"><label>Arrazoia</label><select class="form-control" id="reasons_list_'+x+'" name="transfers['+x+'][reason]"></select></label>'+
        '<label class="col-md-2"><label>Data</label><input type="date" class="form-control" name="transfers['+x+'][date]"></input></label>'+
        '<label class="col-md-1"><label>Hurbilketa</label><br><input type="checkbox" value="1" name="transfers['+x+'][close_up]"></input></label>'+
        '<label class="col-md-2"><span class="red-button btn remove_kinship_row">X</span></div>';

    $(wrapper).prepend(fieldsHtml);

    var prisons = $('#prisons_list_source').find('option').clone();
    var reasons = $('#reasons_list_source').find('option').clone();


    $('#prisons_list_'+x).html(prisons);
    $('#reasons_list_'+x).html(reasons);


}