function manageSentences() {

    $('#add-sentences-row').on('click',function (e) {

        var wrapper = $("#sentences-fields-wrapper");
        var fieldsCounter = $('.sentence-row').length;
        var x = fieldsCounter +  1;

        var fieldsHtml = '<div class="col-md-12 mt10 sentence-row">'+
                '<label class="col-md-2"><input class="form-control" value="0" placeholder="Kondena" type="text" name="sentences['+x+'][sentence]" ></label>'+
                '<label class="col-md-8"><input class="form-control" value="" placeholder="Kondena oharra" type="text" name="sentences['+x+'][sentence_note]" ></label>'+
                '<label class="col-md-2"><span class="red-button btn remove_sentence_row">X</span></div>';

        $(wrapper).prepend(fieldsHtml);

        e.preventDefault();

    });

    $('#main-form').on('click','.remove_sentence_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });
}