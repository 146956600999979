$(document).ready(function()
{
    $('.notification-message').delay(3000).slideUp();

    $(".confirm").on("click",function(e){
        e.preventDefault();

        var r = confirm("Ziur zaude?");
        if (r == true) {
            window.location = $(this).attr('href');
        }
        return false;
    });

    $('[data-toggle="tooltip"]').tooltip();

    $('.more-info').popover();

});
