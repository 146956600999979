function attachKinship() {

    $('#add-kinship-fields-row').on('click',function (e) {

        e.preventDefault();

        addKinshipFields();

    });

    $('#main-form').on('click','.remove_kinship_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });
}

function addKinshipFields(){

    var wrapper = $("#kinship-fields-wrapper");
    var fieldsCounter = $('.kinship-row').length;
    var x = fieldsCounter +  1;

    var fieldsHtml = '<div class="col-md-12 mt10 kinship-row">'+
        '<label class="col-md-5"><select class="form-control" id="prisoner_list_'+x+'" name="kinships['+x+'][prisoner_id2]"></select></label>'+
        '<label class="col-md-5"><select class="form-control" id="kinshipTypes_list_'+x+'" name="kinships['+x+'][kinship]"></select></label>'+
        '<label class="col-md-2"><span class="red-button btn remove_kinship_row">X</span></div>';

    $(wrapper).prepend(fieldsHtml);

    var options = $('#prisoners_list_source').find('option').clone();
    var types_options = $('#kinshipTypes_list_source').find('option').clone();

    console.log(types_options);

    $('#prisoner_list_'+x).html(options);
    $('#kinshipTypes_list_'+x).html(types_options);


}