function displayGraphs() {


    var graphTypes = [
        'illness-graph',
        'illness-classification-graph',
        //'family-age-graph',
        'distance-graph_ee',
        'distance-graph_ef',
        'prisoner-age-graph',
        'visiting-impediment-graph',
        //'prisoners-for-each-county-graph',
        'prisoners-for-each-registry-county-graph',
        'gender-graph',
        'rucksack-child-graph',
        'son-daughter-and-other-children-graph',
        'children-visits-frequency-graph',
        'grade-graph',
        'sentences-penalty_codes-graph',
        'prisoners-penalty-years-graph',
        'sentences-compliance-levels-graph',
        'torture-accusation-graph',
        'expected-liberation-year-graph',
        'visiting-impediment-reasons-graph',
        'prisoners-by-module-type-graph'
    ];

    for(var i = 0; i < graphTypes.length; i++)
    {
        getGraph(graphTypes[i]);
    }


    function getGraph(graphType)
    {
        var spinner = $('#spinner');

        spinner.css('display','block');

        $.ajax({
            type: 'GET',
            data: {
                type: graphType
            },
            url: '/api/graphs-data',
            success: function (data) {

                if(graphType == 'illness-graph')
                {
                    displayPieChart(1,data.data,"Osasun arazoak dituzten presoak");

                }else if(graphType == 'illness-classification-graph')
                {
                    displayPieChart(2,data.data,'Presoen osasunaren arabera');

                }else if(graphType == 'family-age-graph')
                {
                    //displayPieChart(3,data.data,'Senideen adina');

                }else if(graphType == 'distance-graph_ee')
                {
                    displayPieChart(3,data.data,'Distantzia Km-tan espainiar estatuan');

                }else if(graphType == 'distance-graph_ef')
                {
                    displayPieChart(19,data.data,'Distantzia Km-tan frantziar estatuan');

                }else if(graphType == 'prisoner-age-graph')
                {
                    displayPieChart(4,data.data,'Presoen adina');

                }else if(graphType == 'visiting-impediment-graph')
                {
                    displayPieChart(5,data.data,'Bixita arazoak dituzten senideak dituzten presoak');
                }else if(graphType == 'prisoners-for-each-county-graph')
                {
                    //displayPieChart(7,data.data,'Bizitokitzat duten herrialdearen araberako preso kopurua');
                } else if(graphType == 'prisoners-for-each-registry-county-graph')
                {
                    displayPieChart(6,data.data,'Erroldatzat duten herrialdearen araberako preso kopurua');

                }else if(graphType == 'gender-graph')
                {
                    displayPieChart(7,data.data,'Presoak generoaren arabera');

                }
                else if(graphType == 'son-daughter-and-other-children-graph')
                {
                    displayPieChart(8,data.data,'Gurasoak / Gurasoak ez direnak ikusteko haurren bisitak');

                }
                else if(graphType == 'rucksack-child-graph')
                {
                    displayPieChart(14,data.data,'Seme-alabak');

                }
                else if(graphType == 'grade-graph')
                {
                    displayPieChart(10,data.data,'Graduaren arabera');

                }
                else if(graphType == 'sentences-penalty_codes-graph')
                {
                    displayPieChart(11,data.data,'Sententziak kodearen arabera');

                }
                else if(graphType == 'prisoners-penalty-years-graph')
                {
                    displayPieChart(12,data.data,'Sententziak zigor urte arabera');
                }
                else if(graphType == 'sentences-compliance-levels-graph')
                {
                    displayPieChart(13,data.data,'Sententziak zigor betetze mailaren arabera');
                }
                else if(graphType == 'torture-accusation-graph')
                {
                    displayPieChart(15,data.data,'Tortura salaketak');
                }
                else if(graphType == 'expected-liberation-year-graph')
                {
                    displayPieChart(16,data.data,'Irteera dataren arabera');
                }
                else if(graphType == 'visiting-impediment-reasons-graph')
                {
                    displayPieChart(17,data.data,'Bisita oztopoen arrazoien arabera');
                }
                else if(graphType == 'visiting-impediment-reasons-graph')
                {
                    displayPieChart(18,data.data,'Modulo motaren arabera');
                }

                spinner.css('display','none');

            }

        });

        function displayTable(number,responseData,title) {

            var data = new google.visualization.DataTable();

            var options = {
                title: title,
                width: '100%',
                height: 'auto'
            };

            data.addColumn('string','Maiztasuna');

            data.addColumn('number','kopurua');

            data.addRows(responseData.results);

            var chart = new google.visualization.Table(document.getElementById('chart-'+number));

            chart.draw(data, options);

            $('#chart-'+number).prepend('<h4 class="text-center">'+title+'</h4>');
        }


        function displayPieChart(number, responseData,title) {

            var data = google.visualization.arrayToDataTable(responseData.results);

            var options = {
                title: title
            };


            var chart = new google.visualization.PieChart(document.getElementById('chart-'+number));

            chart.draw(data, options);



        }

    }

}