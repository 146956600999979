function manageDeliveries(number) {

    $('#add-deliveries-row').on('click',function (e) {

        var wrapper = $("#deliveries-fields-wrapper");
        var fieldsCounter = $('.deliveries-row').length;
        var x = fieldsCounter +  1;

        var fieldsHtml = '<div class="col-md-12 mt10 delivery-row">'+
            '<label class="col-md-2">Entrega data'+
            '<div class="input-group date" id="ia_arrest_date['+ x +']">'+
            '<input type="text" class="form-control" name="deliveries['+ x +'][delivery_date]">'+
            '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
            '</div>'+
            '</label>'+
            '<label class="col-md-2">Itzulera data'+
            '<div class="input-group date" id="ia_arrest_date['+ x +']">'+
            '<input type="text" class="form-control" name="deliveries['+ x +'][return_date]">'+
            '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
            '</div>'+
            '</label>'+


            '<label class="col-md-6">Oharra<input type="text" class="form-control" name="deliveries['+ x +'][comments]"></label>'+

            // '<input class="form-control" value="0" placeholder="Kondena" type="text" name="sentences['+x+'][sentence]" ></label>'+
            //'<label class="col-md-6"><input class="form-control" value="" placeholder="Entrega oharra" type="text" name="sentences['+x+'][sentence_note]" ></label>'+
            '<label class="col-md-2"><span class="red-button btn remove_sentence_row">X</span></div>';

        $(wrapper).prepend(fieldsHtml);

        e.preventDefault();

    });

    $('#main-form').on('click','.remove_delivery_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });
}