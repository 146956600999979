function manageInternationalArrests() {

    $('#add-international-arrest-row').on('click',function (e) {
        var wrapper = $("#international-arrests-fields-wrapper");
        var fieldsCounter = $('.international-arrest-row').length;
        var x = fieldsCounter;

        var fieldsHtml = '<div class="international-arrest-row mt20 col-md-10">'+
                                '<div class="col-md-11">'+
                                    '<div class="row">'+
                                        '<div class="col-lg-4">'+
                                            '<label>Nazioartean atxilotua</label>'+
                                            '<input class="switch" checked="checked" name="international_arrests['+x+'][international_arrest]" type="checkbox" value="1">'+
                                        '</div>'+
                                        '<label class="col-lg-4" >Estatua<span class="text-red">*</span>'+
                                            '<select class="form-control" name="international_arrests['+x+'][ia_state]" id="international_arrest_state_'+x+'"></select>'+
                                        '</label>'+
                                    '</div>'+
                                    '<div class="row">'+
                                        '<div class="col-lg-3" >'+
                                            '<label>Atxiloketa eguna<span class="text-red">*</span></label>'+
                                            '<div class="input-group date">'+
                                                '<input class="form-control" name="international_arrests['+x+'][ia_arrest_date]" type="text">'+
                                                '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
                                            '</div>'+
                                        '</div>'+
                                        '<div class="col-lg-3" >'+
                                            '<label>Espetxeratze eguna<span class="text-red">*</span></label>'+
                                            '<div class="input-group date">' +
                                                '<input class="form-control" name="international_arrests['+x+'][ia_imprisonment_date]" type="text">'+
                                                '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'+
                                    '<div class="row">'+
                                        '<div class="col-lg-4">'+
                                            '<label>Nazioartean Kondenatua</label>'+
                                            '<input class="switch" checked="checked" name="international_arrests['+x+'][ia_sentence]" type="checkbox" value="1">'+
                                        '</div>'+
                                        '<label class="col-lg-2" >Urteak<span class="text-red">*</span>'+
                                            '<input class="form-control" name="international_arrests['+x+'][ia_penalty_years]" type="number">'+
                                        '</label>'+
                                        '<label class="col-lg-1" >Hilabeteak<span class="text-red">*</span>'+
                                            '<input class="form-control" name="international_arrests['+x+'][ia_penalty_months]" type="number">'+
                                        '</label>'+
                                        '<label class="col-lg-1" >Egunak<span class="text-red">*</span>'+
                                            '<input class="form-control" name="international_arrests['+x+'][ia_penalty_days]" type="number">'+
                                        '</label>'+
                                    '</div>'+
                                    '<div class="row">'+
                                        '<div class="col-lg-4">'+
                                            '<label>Nazioartean erantzunkizun zibila</label>'+
                                            '<input class="switch" checked="checked" name="international_arrests['+x+'][ia_civil_liability]" type="checkbox" value="1">'+
                                        '</div>'+
                                    '</div>'+
                                    '<div class="row">'+
                                        '<div class="col-lg-4">'+
                                            '<label>Kondena bukatze data</label>'+
                                            '<div class="input-group date">' +
                                                '<input class="form-control" name="international_arrests['+x+'][ia_sentence_end_date]" type="text">'+
                                                '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'+
                                    '<div class="row">'+
                                        '<div class="col-lg-3">'+
                                            '<label for="international_arrest">Nazioartean atxilotu eta zigortua, Fitxategia igo:</label>'+
                                            '<input class="form-control" name="international_arrests['+x+'][file]" type="file">'+
                                        '</div>'+
                                    '</div>'+
                                    '<div class="row">'+
                                        '<div class="col-lg-3" >'+
                                        '<label>Behin betiko entrega<span class="text-red">*</span></label>'+
                                            '<div class="input-group date">' +
                                                '<input class="form-control" name="international_arrests['+x+'][ia_final_delivery]" type="text">'+
                                                '<span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>'+
                                            '</div>'+
                                        '</div>'+
                                   '</div>' +
                                    '<div class="row">'+
                                        '<div class="col-lg-4">'+
                                            '<label>Balizko nazioarteko akumulazioa</label>'+
                                            '<input class="switch" checked="checked" name="international_arrests['+x+'][ia_possible_international_accumulation]" type="checkbox" value="1">'+
                                        '</div>'+
                                    '</div>'+
                                    '<br/><hr>'+
                                '</div>'+
                                '<label class="col-md-1">'+
                                    '<span class="red-button btn remove_international_arrests_row">X</span>'+
                                '</label>'+
                            '</div>'+
                        '</div>';



        $(wrapper).prepend(fieldsHtml);

        $(".switch").bootstrapSwitch({
            size: 'small',
            onColor: 'success',
            onText: 'bai',
            offText: 'ez'
        });

        var text_options = $('#states_text_source').find('option').clone();
        $('#international_arrest_state_'+x).html(text_options);

        e.preventDefault();

    });

    $('#main-form').on('click','.remove_international_arrests_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });

}