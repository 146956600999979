function manageWorksAndStudies() {

    $('#add-works-and-studies-row').on('click',function (e) {

        var wrapper = $("#works-and-studies-fields-wrapper");
        var fieldsCounter = $('.work-and-study-row').length;
        var x = fieldsCounter;

        var fieldsHtml = '<div class="work-and-study-row mt20 col-md-12">'+
            '<div class="form-group">'+
                '<div class="col-lg-12">'+
                        '<div class="col-lg-3">'+
                            '<label>Lan destinoa<span class="text-red">*</span></label>'+
                            '<input class="switch" name="works_and_studies['+x+'][destination_work]" type="checkbox" value="1">'+
                        '</div>'+

                        '<div class="col-lg-3">'+
                            '<label>Lan destino eskaera<span class="text-red">*</span></label>'+
                            '<input class="switch" id="destination_work_requested" name="works_and_studies['+x+'][destination_work_requested]" type="checkbox" value="1">'+
                        '</div>'+

                        '<div class="col-lg-3">'+
                            '<label for="madrid_verdict_file">Igo fitxategia:</label>'+
                            '<input class="form-control" name="works_and_studies['+x+'][work-file]" type="file">'+
                        '</div>'+
                '</div>'+

            '</div>';

        $(wrapper).prepend(fieldsHtml);

        $(".switch").bootstrapSwitch({
            size: 'small',
            onColor: 'success',
            onText: 'bai',
            offText: 'ez'
        });

        var text_options = $('#studies_schools_text_source').find('option').clone();
        $('#studies_schools_text_'+x).html(text_options);

        e.preventDefault();

    });

    $('#main-form').on('click','.remove_work_and_study_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });
}