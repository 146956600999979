function ezabatu(event,token){
    event.stopPropagation(); // Stop stuff happening
    event.preventDefault(); // Totally stop stuff happening
    var ida=event.target.id;
    //console.log(ida+"kepa"+event.target.nodeName);
    var urla=event.target.href;
    if (urla==undefined){
        var urla=$(event.target).parent().attr('href');
    }
    console.log(urla);
    var csrf = token;
    //alert (ida +" k");
    bootbox.confirm("Ziur zaude <strong>"+$(event.target).data('text')+"</strong>", function(result) {
        if (result==true){
            console.log("ajax eskaria egin !!");
            $.ajax({
                headers: {
                    'X-CSRF-Token': csrf ,
                    "Accept": "application/json"
                },
                url: urla,
                type: 'DELETE',
                //method: 'DELETE',
                data: {id:ida},
                success: function(data, textStatus, jqXHR)
                {
                    $(event.target).closest('li').remove();

                    console.log("EGina!!");
                },
                error: function(jqXHR, textStatus, errorThrown)
                {
                    //console.log(errorThrown);
                    //console.log(textStatus);
                    //console.log(jqXHR['responseText']);
                    console.log(JSON.parse(jqXHR.responseText).error);
                    alert(JSON.parse(jqXHR.responseText).error);
                }
            });
        }
    });
}

