$(document).ready(function()
{
    $('.bootstrap-switch-id-free').on('switchChange.bootstrapSwitch', function(event, state) {
        if (state) {
            $('.hide_free_data').show();
        } else {
            $('.hide_free_data').hide();
            $("select[name=free_status]").val('');
            $("input[name=free_date]").val('');
        }
        event.preventDefault();
    });

});


