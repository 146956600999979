function manageOtherActivities() {

    $('#add-other-activities-row').on('click',function (e) {

        var wrapper = $("#other-activities-fields-wrapper");
        var fieldsCounter = $('.activity-row').length;
        var x = fieldsCounter;

        var fieldsHtml = '<div class="activity-row mt20 col-md-12">'+


            '<label class="col-lg-3">Programatuak <span class="text-red">*</span>'+
                '<select class="form-control" id="other_programmed_activities_text_'+x+'" name="other_activities['+x+'][programmed_activity]"></select>'+
            '</label>'+
            '<label class="col-lg-3">Ez programatuak <span class="text-red">*</span>'+
                '<select class="form-control" id="other_not_programmed_activities_text_'+x+'" name="other_activities['+x+'][not_programmed_activity]"></select>'+
            '</label>'+
            '<label class="col-lg-4">Fitxategia<input class="form-control" name="other_activities['+x+'][file]" type="file"></label>'+
            '<label class="col-md-2"><span class="red-button btn remove_penalty_row">X</span></div>';

        $(wrapper).prepend(fieldsHtml);

        var text_options = $('#other_activities_text_source').find('option').clone();
        $('#other_not_programmed_activities_text_'+x).html(text_options);

        var text_options = $('#other_activities_text_source').find('option').clone();
        $('#other_programmed_activities_text_'+x).html(text_options);

        e.preventDefault();

    });

    $('#main-form').on('click','.remove_activity_row',function (e) {

        $(this).parent().parent('div').remove();

        e.preventDefault();
    });
}